

































































































































































































































































import Vue from "vue";
import {
  mdiWeb,
  mdiFacebook,
  mdiInstagram,
  mdiTwitter,
  mdiYoutube,
  mdiMapMarker,
  mdiPhoneClassic,
  mdiPhone,
  mdiPlus,
  mdiStar,
} from "@mdi/js";

import {
  Company,
  SearchResults,
  CompanySpecialBold,
  CompanySpecialUpper,
  CompanySpecialDoble,
  CompanyPlanPaid,
  CompanyPlanFree,
} from "../store/types";
import CategoryList from "@/components/CategoryList.vue";
import { getUrl, OpenSocialLink } from "@/helpers";
export default Vue.extend({
  name: "Search",
  beforeMount() {
    this.$store.dispatch("directorio/Search", { search: this.$route.query.q });
  },
  components: {
    CategoryList,
  },
  data() {
    return {
      loading: false,
      CompanyPlanPaid,
      CompanyPlanFree,

      mdiWeb,
      mdiFacebook,
      mdiInstagram,
      mdiTwitter,
      mdiYoutube,
      mdiMapMarker,
      mdiPhoneClassic,
      mdiPhone,
      mdiPlus,
      mdiStar,
    };
  },
  computed: {
    results(): SearchResults {
      return this.$store.getters["directorio/searchResults"];
    },
    companies(): Company[][] {
      const companies: Company[][] = [];
      const ccs = this.results.companies;
      let lastTown = "";
      let aux: Company[] = [];
      ccs.forEach((c) => {
        if (c.town !== lastTown && lastTown !== "") {
          companies.push(aux);
          lastTown = c.town;
          aux = [];
          aux.push(c);
        } else {
          lastTown = c.town;
          aux.push(c);
        }
      });
      companies.push(aux);
      return companies;
    },
    events() {
      return this.results.events;
    },
  },
  methods: {
    isBold(specials: number) {
      return (specials & CompanySpecialBold) !== 0;
    },
    isUpper(specials: number) {
      return (specials & CompanySpecialUpper) !== 0;
    },
    isDoble(specials: number) {
      return (specials & CompanySpecialDoble) !== 0;
    },
    getSpecialClasses(specials: number) {
      let classes = "";
      classes += this.isBold(specials) ? "font-weight-bold " : "";
      classes += this.isUpper(specials) ? "text-uppercase " : "";
      return classes;
    },
    OpenSocialLink,
    LayoutMasonry() {
      this.$redrawVueMasonry("masonry");
      setTimeout(() => this.$redrawVueMasonry("masonry"), 1000);
    },
    getUrl,
  },
  watch: {
    $route: function () {
      if (this.$route.name === "Search") {
        this.$store.dispatch("directorio/Search", {
          search: this.$route.query.q,
        });
      }
    },
    events() {
      if (this.events.length > 0) {
        this.$redrawVueMasonry("masonry");
        setTimeout(() => this.$redrawVueMasonry("masonry"), 1000);
      }
    },
  },
});
