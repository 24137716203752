


























import Vue from "vue";
import {
  mdiWeb,
  mdiFacebook,
  mdiInstagram,
  mdiTwitter,
  mdiYoutube,
} from "@mdi/js";

import BlockRenderer from "@/components/BlockRenderer.vue";
import { Event } from "../store/types";

import { getUrl, OpenSocialLink } from "@/helpers";

interface Data {
  article: any;
  loading: boolean;
  mdiWeb: string;
  mdiFacebook: string;
  mdiInstagram: string;
  mdiTwitter: string;
  mdiYoutube: string;
}

export default Vue.extend({
  name: "Article",
  components: {
    BlockRenderer,
  },
  mounted() {
    this.Load();
  },
  data(): Data {
    return {
      article: null,
      loading: false,
      mdiWeb,
      mdiFacebook,
      mdiInstagram,
      mdiTwitter,
      mdiYoutube,
    };
  },
  methods: {
    async Load() {
      const articleId = this.$route.params.id;
      if (this.loading === true) {
        return;
      }
      this.loading = true;
      const article = await this.$store.dispatch(
        "directorio/GetArticle",
        articleId
      );
      if (article) {
        this.article = article;
        this.$refs.br.SetBlocks(this.article.blocks.blocks);
      }
      this.loading = false;
    },
    OpenSocialLink,
    getUrl,
  },
});
